import React from 'react';
import './Subscribe.scss';

function Subscribe() {
  function sendEmail(emailInput, event) {
    event.preventDefault();
    // getform.io Paid plan required!
    fetch('https://getform.io/f/7a73e411-b465-47b0-b408-4d935803c652', {
      method: 'POST',
      body: { email: emailInput },
    })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  }

  return (
    <div className='subscribe-page animate__animated animate__slideInUp'>
      <section className='section subscribe-section'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-half is-offset-one-quarter'>
              Already have an account? <b>Login</b>
              <h1>
                To read this research article and others like it subscribe today
                for a 3 month trial.
              </h1>
              <form
                action='https://getform.io/f/7a73e411-b465-47b0-b408-4d935803c652'
                method='POST'
              >
                <div className='field'>
                  <div className='control is-medium'>
                    <input
                      className='input is-medium'
                      type='email'
                      name='email'
                      placeholder='Your email'
                    />
                  </div>
                  <button
                    type='submit'
                    className='button is-primary'
                    id='btn-subscribe'
                    // onClick={(e) => sendEmail("test@test.com", e)}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Disable legal section until approved */}
      {/*                                      */}
      {/*<section className="footer-section section">*/}
      {/*  <div className="container">*/}
      {/*    <footer className="legal-notice">*/}
      {/*      <b>No commitment required, cancel anytime.</b>*/}
      {/*      <br />*/}
      {/*      Your payment method will automatically be charged after 12 weeks.*/}
      {/*      You will not be charged for the introductory period, and thereafter*/}
      {/*      will be charged the standard rate. All subscriptions renew*/}
      {/*      automatically. You can cancel anytime. Mobile apps are not supported*/}
      {/*      on all devices. These offers are not available for current*/}
      {/*      subscribers. Other restrictions and taxes may apply. Offers and*/}
      {/*      pricing are subject to change without notice.*/}
      {/*    </footer>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </div>
  );
}

export default Subscribe;
