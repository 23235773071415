import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as moment from 'moment';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import './index.scss';
import 'animate.css';
import cmsApiSerivce from '../api/cmsApiService';
import LeftArrow from './../images/left-arrow.svg';
import RightArrow from './../images/right-arrow.svg';
import Navbar, { NavbarMobile } from '../components/NavBar/Navbar';
import Subscribe from '../components/Subscribe/Subscribe';
import Footer from '../components/Footer';

const IndexPage = () => {
  const [timerRef, setTimeRef] = useState();

  // Only local images can be "preload", so we are unable to host images on ImageKit.
  // Preloading is required to avoid flicker on image change.
  // On page load, if window size is < 500px, then we load mobile images.
  // let imageUrls = [
  //   '/article_11_20220215.jpg',
  //   '/hero_2.jpg',
  //   '/hero_3.jpg',
  //   '/article_6_20220308.jpg',
  // ];
  // // Website it build on NodeJS server, where 'window' is not available.
  const isBrowser = () => typeof window !== 'undefined';
  const useMobileImage = isBrowser() && window.innerWidth < 500;
  // if (useMobileImage) {
  //   imageUrls = [
  //     '/article_11_20220215_m.jpg',
  //     '/hero_2_m.jpg',
  //     '/article_11_20220215_m.jpg',
  //     '/hero_3_m.jpg',
  //   ];
  // }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [bgImgs, setBgImgs] = useState([]);
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    const data = await cmsApiSerivce().get(
      'public-news-articles?populate=image,country_tags&sort=datetime:desc'
    );
    setArticles(data.data);
  };

  useEffect(() => {
    getArticles();
  }, []);

  const goToNextImage = React.useCallback(() => {
    setCurrentIndex((index) => {
      if (index + 1 > articles.length - 1) {
        return 0;
      }
      return index + 1;
    });
  }, [articles]);

  // When the next image arrow is manually clicked,
  // then we need to start the timer again from 0.
  const resetTimer = React.useCallback(() => {
    clearInterval(timerRef);
    const timer = setInterval(goToNextImage, 6000);
    setTimeRef(timer);
  }, [goToNextImage, timerRef]);

  const nextClicked = React.useCallback(
    (e) => {
      e.stopPropagation();
      resetTimer();
      goToNextImage();
    },
    [goToNextImage, resetTimer]
  );

  const prevClicked = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
      resetTimer();
    },
    [currentIndex, resetTimer]
  );

  // Automatically go to next image (every 6 seconds)
  useEffect(() => {
    const timer = setInterval(goToNextImage, 6000);
    setTimeRef(timer);

    return () => {
      clearInterval(timer);
    };
  }, [goToNextImage]);

  // Render public news
  function renderArticle() {
    const articleData = articles[currentIndex]
      ? articles[currentIndex].attributes
      : undefined;
    const articleCountryTags = articleData?.country_tags.data.length
      ? articleData.country_tags.data
          .map((item) => item.attributes.name)
          .join(', ')
      : '';

    if (articleData) {
      return (
        <div className='caption'>
          <div className='small-uppercase mb-4'>{articleCountryTags}</div>
          <h1>{articleData.title}</h1>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className='markdown-custom'
          >
            {articleData.content}
          </ReactMarkdown>
          <div className='mt-5'>
            {moment(articleData.datetime).format('DD MMMM YYYY')}
          </div>
        </div>
      );
    } else {
      return <div className='caption'></div>;
    }
  }

  useEffect(() => {
    if (articles && articles.length > 0) {
      const bgImgs = articles.map((article) => {
        let imageUrls = {};

        if (article.attributes.image.data.attributes.url) {
          imageUrls.small =
            article.attributes.image.data.attributes.url + '?tr=w-1000';

          imageUrls.large =
            article.attributes.image.data.attributes.url + '?tr=w-2200';
        }

        return imageUrls;
      });

      setBgImgs(bgImgs);
    }
  }, [articles]);

  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     '--image-link',
  //     `url("${articleData[currentIndex]}")`
  //   );
  // }, [currentIndex]);

  return (
    <>
      <Helmet>
        <title>Wigram - Macro economic analysis and research</title>
        <meta
          name='description'
          content='Wigram provides leading analysis and research to leading global hedge funds with a focus on Asia-Pacific markets.'
        />
        {/* Preload images */}
        {/* <link rel='preload' href={imageUrls[0]} as='image' />
        <link rel='preload' href={imageUrls[1]} as='image' />
        <link rel='preload' href={imageUrls[2]} as='image' />
        <link rel='preload' href={imageUrls[3]} as='image' />
        <link rel='preload' href={imageUrls[4]} as='image' />
        <link rel='preload' href={imageUrls[5]} as='image' /> */}
      </Helmet>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <div className='index-page'>
        <NavbarMobile showLogin={false} />
        <div className='section is-hidden-tablet is-hidden-desktop mobile-heading'>
          <div className='columns'>
            <div className='column'>
              <h1>
                Providing in-depth macro analysis based on data driven research.
              </h1>
            </div>
          </div>
        </div>

        <section
          className='hero is-fullheight bg-img'
          // style={{ backgroundImage: getBackgroundUrl() }}
        >
          <div className='bg-wrapper'>
            {bgImgs.length > 0 && (
              <>
                {bgImgs.map((bg, idx) => (
                  <div
                    key={idx}
                    className={`bg-item ${idx === currentIndex ? 'show' : ''}`}
                    style={{
                      backgroundImage: `url(${
                        useMobileImage ? bg.small : bg.large
                      })`,
                    }}
                  ></div>
                ))}
              </>
            )}
          </div>
          <div className='hero-head'>
            <Navbar showLogin={false} />
          </div>

          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-hidden-mobile'>
                <div className='column is-three-fifths-widescreen'>
                  <h1>
                    Providing in-depth macro analysis based on data driven
                    research.
                  </h1>
                </div>
              </div>

              <div className='columns counter-article-row'>
                <div className='column is-hidden-mobile is-three-fifths-widescreen'>
                  {Boolean(articles.length) && (
                    <div className='prev-next-wrapper'>
                      <div className='slide-count'>
                        <div className='count'>
                          {('0' + (currentIndex + 1)).slice(-2)}
                        </div>
                        <div className='count-total'>
                          / {('0' + articles.length).slice(-2)}
                        </div>
                      </div>
                      <LeftArrow onClick={prevClicked} />
                      <RightArrow onClick={nextClicked} />
                    </div>
                  )}
                </div>
                <div className='column is-two-fifths-widescreen'>
                  {renderArticle()}
                </div>
                <div className='column is-hidden-desktop is-hidden-tablet'>
                  <div className='prev-next-wrapper prev-next-mobile'>
                    <div className='slide-count'>
                      <div className='count'>{currentIndex + 1}</div>
                      <div className='count-total'>/ {articles.length}</div>
                    </div>
                    <LeftArrow onClick={prevClicked} />
                    <RightArrow onClick={nextClicked} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

function Modal({ showModal, setShowModal }) {
  return (
    <div
      id='modal-id'
      className={
        showModal
          ? 'modal animate__animated animate__fadeIn is-active'
          : 'modal'
      }
    >
      <div
        className='modal-background'
        onClick={() => setShowModal(false)}
      ></div>
      <div className='modal-content'>
        <Subscribe />
      </div>
      <button
        className='modal-close is-large'
        aria-label='close'
        onClick={() => setShowModal(false)}
      ></button>
    </div>
  );
}

export default IndexPage;
